import React from 'react';
import PrivacyPolicy from "../components/privacy-policy";
import CookiesNotice from '../components/CookieNotice';

export default function contacts() {  
  return (
    <div>
      <PrivacyPolicy />
      <CookiesNotice />
    </div>
  );
}
